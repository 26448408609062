"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _EmbedChatManager_instances, _EmbedChatManager_PAGE_ID, _EmbedChatManager_CONFIG, _EmbedChatManager_IFRAME, _EmbedChatManager_HIDDEN_PATHS, _EmbedChatManager_CURRENT_PATHNAME, _EmbedChatManager_ALLOWED_DOMAINS, _EmbedChatManager_getParentPathname, _EmbedChatManager_getParentDomain, _EmbedChatManager_checkAllowedDomain, _EmbedChatManager_overrideHistoryMethods, _EmbedChatManager_startPathnameWatcher, _EmbedChatManager_handlePathnameChange, _EmbedChatManager_isMobile, _EmbedChatManager_checkViewport, _EmbedChatManager_listenIframe, _EmbedChatManager_scrollToHeader, _EmbedChatManager_disableScrollAndRefresh, _EmbedChatManager_enableScrollAndRefresh, _EmbedChatManager_disableParentScaling, _EmbedChatManager_changeIframePosition, _EmbedChatManager_changeIframeSize, _EmbedChatManager_initIframe, _EmbedChatManager_isValidParentDomain;
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbedChatManager = void 0;
const env_1 = require("./env");
const base_1 = require("./base");
/**quản lý sdk embed chat */
class EmbedChatManager extends base_1.Base {
    /**
     * Khởi tạo
     * @param page_id id trang
     * @param config cấu hình
     * @param is_debug có debug không
     */
    constructor(page_id, config, is_debug) {
        /** khai báo lớp cơ sở */
        super(is_debug);
        _EmbedChatManager_instances.add(this);
        /**id trang */
        _EmbedChatManager_PAGE_ID.set(this, void 0);
        /**cấu hình hiển thị */
        _EmbedChatManager_CONFIG.set(this, void 0);
        /**iframe bong bóng chat */
        _EmbedChatManager_IFRAME.set(this, void 0);
        /** hidden path */
        _EmbedChatManager_HIDDEN_PATHS.set(this, []
        /** Pathname hiện tại */
        );
        /** Pathname hiện tại */
        _EmbedChatManager_CURRENT_PATHNAME.set(this, __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_getParentPathname).call(this)
        /** Domain được phép */
        );
        /** Domain được phép */
        _EmbedChatManager_ALLOWED_DOMAINS.set(this, []
        /**
         * Khởi tạo
         * @param page_id id trang
         * @param config cấu hình
         * @param is_debug có debug không
         */
        );
        /** kiểm tra id trang có tồn tại không */
        if (!page_id)
            throw 'MISSING_PAGE_ID';
        /** khởi tạo id trang */
        __classPrivateFieldSet(this, _EmbedChatManager_PAGE_ID, page_id, "f");
        /** khởi tạo cấu hình */
        __classPrivateFieldSet(this, _EmbedChatManager_CONFIG, config || {}, "f");
        /** khởi tạo iframe */
        __classPrivateFieldSet(this, _EmbedChatManager_IFRAME, document.createElement('iframe'), "f");
        /** Kiểm tra meta viewport khi khởi tạo */
        this.HAS_VIEWPORT = !!document.querySelector('meta[name="viewport"]');
    }
    /**khởi động bong bóng chat */
    init() {
        this.debug('init', 'page_id', __classPrivateFieldGet(this, _EmbedChatManager_PAGE_ID, "f"));
        this.debug('init', 'config', JSON.stringify(__classPrivateFieldGet(this, _EmbedChatManager_CONFIG, "f"), null, 4));
        this.debug('Version : 2.0');
        /** Lấy domain của page cha */
        const PARENT_DOMAIN = __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_getParentDomain).call(this);
        this.debug('init', 'parent_domain', PARENT_DOMAIN);
        /** Kiểm tra nếu domain không hợp lệ thì dừng SDK */
        if (!__classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isValidParentDomain).call(this, PARENT_DOMAIN)) {
            console.warn(`[BBH] Parent domain "${PARENT_DOMAIN}" is not allowed. SDK will not initialize.`);
            return;
        }
        /** kiểm tra viewport trên thiết bị di động và thay đổi nếu cần */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_checkViewport).call(this);
        /** chặn zoom trên mobile */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_disableParentScaling).call(this);
        /** lắng nghe thông điệp từ iframe */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_listenIframe).call(this);
        /** Theo dõi sự thay đổi của pathname trong page cha */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_startPathnameWatcher).call(this);
        /** khởi tạo iframe và tiêm vào trang web */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_initIframe).call(this);
    }
    /**
     * Destroy SDK
     */
    destroy() {
        this.debug('destroy');
        /** Gỡ iframe */
        __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").remove();
        /** Gỡ chặn scaling */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_enableScrollAndRefresh).call(this);
        /** Gỡ chặn scroll */
        __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_enableScrollAndRefresh).call(this);
    }
}
exports.EmbedChatManager = EmbedChatManager;
_EmbedChatManager_PAGE_ID = new WeakMap(), _EmbedChatManager_CONFIG = new WeakMap(), _EmbedChatManager_IFRAME = new WeakMap(), _EmbedChatManager_HIDDEN_PATHS = new WeakMap(), _EmbedChatManager_CURRENT_PATHNAME = new WeakMap(), _EmbedChatManager_ALLOWED_DOMAINS = new WeakMap(), _EmbedChatManager_instances = new WeakSet(), _EmbedChatManager_getParentPathname = function _EmbedChatManager_getParentPathname() {
    try {
        return window.parent.location.pathname || '/';
    }
    catch (error) {
        console.warn('[BBH] Không thể lấy pathname từ page cha');
        return '/';
    }
}, _EmbedChatManager_getParentDomain = function _EmbedChatManager_getParentDomain() {
    var _a;
    try {
        return ((_a = window.parent) === null || _a === void 0 ? void 0 : _a.location.hostname) || null;
    }
    catch (error) {
        console.warn('[BBH] Không thể lấy domain của page cha do CORS.');
        return null;
    }
}, _EmbedChatManager_checkAllowedDomain = function _EmbedChatManager_checkAllowedDomain() {
    const PARENT_DOMAIN = __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_getParentDomain).call(this);
    /**
     * Nếu không có domain cha thì dừng SDK
     */
    if (!PARENT_DOMAIN)
        return false;
    /**
     * Nếu không có domain nào trong danh sách cho phép thì cho phép tất cả
     */
    if (!__classPrivateFieldGet(this, _EmbedChatManager_ALLOWED_DOMAINS, "f").some(domain => PARENT_DOMAIN === domain)) {
        console.warn(`[BBH] Domain ${PARENT_DOMAIN} is not in allowedDomains. SDK will not initialize.`);
        return false;
    }
    console.log(`[BBH] Domain ${PARENT_DOMAIN} is allowed. SDK continues.`);
    return true;
}, _EmbedChatManager_overrideHistoryMethods = function _EmbedChatManager_overrideHistoryMethods() {
    /**
     * Phương thức pushState gốc
     */
    const originalPushState = history.pushState;
    /**
     * Phương thức replaceState gốc
     */
    const originalReplaceState = history.replaceState;
    /**
     * Hàm gửi sự kiện khi pathname thay đổi
     */
    const triggerEvent = () => {
        /**
         * Gửi sự kiện khi pathname thay đổi
         */
        window.dispatchEvent(new Event('BBH_PATHNAME_CHANGE'));
    };
    /**
     * Override phương thức pushState và replace
     */
    history.pushState = function (...args) {
        originalPushState.apply(history, args);
        triggerEvent();
    };
    /**
     *  Override phương thức replaceState
     * @param args Tham số
     */
    history.replaceState = function (...args) {
        originalReplaceState.apply(history, args);
        triggerEvent();
    };
    console.log('[BBH] History methods overridden');
}, _EmbedChatManager_startPathnameWatcher = function _EmbedChatManager_startPathnameWatcher() {
    /**
     * Lấy pathname ban đầu
     */
    let last_pathname = __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_getParentPathname).call(this);
    /**
     * Hàm kiểm tra pathname
     */
    const checkPathname = () => {
        /**
         * Lấy pathname hiện tại
         */
        const CURRENT_PATHNAME = __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_getParentPathname).call(this);
        /**
         * Nếu pathname hiện tại khác với pathname trước đó
         */
        if (CURRENT_PATHNAME !== last_pathname) {
            this.debug('[BBH] Parent Pathname Changed:', CURRENT_PATHNAME);
            /**
             * Cập nhật last_pathname
             */
            last_pathname = CURRENT_PATHNAME;
            /** Thực hiện hành động cần thiết khi pathname thay đổi */
            if (CURRENT_PATHNAME !== null) {
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_handlePathnameChange).call(this, CURRENT_PATHNAME);
            }
        }
    };
    /** Override các phương thức của History API */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_overrideHistoryMethods).call(this);
    /** Lắng nghe sự kiện popstate (back/forward) */
    window.addEventListener('popstate', checkPathname);
    /** Lắng nghe sự kiện pathname thay đổi */
    window.addEventListener('BBH_PATHNAME_CHANGE', checkPathname);
    /**
     * Log thông báo
     */
    this.debug('[BBH] Pathname watcher started');
    /** 🔥 GỌI NGAY LÚC KHỞI ĐỘNG ĐỂ CHECK TRẠNG THÁI HIỆN TẠI */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_handlePathnameChange).call(this, last_pathname);
}, _EmbedChatManager_handlePathnameChange = function _EmbedChatManager_handlePathnameChange(newPathname) {
    /** Cập nhật CURRENT_PATHNAME */
    const SHOULD_HIDE = __classPrivateFieldGet(this, _EmbedChatManager_HIDDEN_PATHS, "f").some(path => newPathname.includes(path));
    /**
     * Nếu pathname hiện tại nằm trong danh sách hidden thì ẩn iframe
     */
    if (SHOULD_HIDE) {
        this.debug('[BBH] Pathname matched hidden list -> Ẩn iframe');
        __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.display = 'none';
    }
    else {
        this.debug('[BBH] Pathname not in hidden list -> Hiển thị iframe');
        __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.display = 'block';
    }
}, _EmbedChatManager_isMobile = function _EmbedChatManager_isMobile() {
    /* tất cả các màn nhỏ hơn tablet thì là điện thoại */
    /**
     * Các màn nhỏ hơn tablet đều là điện thoại
     */
    const IS_SMALL_SCREEN = window.innerWidth < 768;
    /**
     *  return kết quả
     */
    // return IS_SMALL_SCREEN || IS_HIGH_DPI
    return IS_SMALL_SCREEN;
}, _EmbedChatManager_checkViewport = function _EmbedChatManager_checkViewport() {
    /** chỉ cho chạy trên điện thoại */
    if (!__classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this))
        return;
    /** kiểm tra nếu chưa tồn tại thẻ thì tạo mới */
    let META_TAG = document.querySelector('meta[name="viewport"]');
    /**
     * Nếu chưa có thẻ thì tạo mới
     */
    if (META_TAG) {
        // Cập nhật trạng thái biến HAS_VIEWPORT
        this.HAS_VIEWPORT = true;
    }
}, _EmbedChatManager_listenIframe = function _EmbedChatManager_listenIframe() {
    window.addEventListener('message', $event => {
        /**dữ liệu iframe gửi cho sdk */
        const PAYLOAD = $event.data;
        /** bóc tách dữ liệu */
        const { from, is_show, is_quick_chat, height, media_url, position, bottom, right, left, list_hidden_path, allowed_domains, } = PAYLOAD;
        this.debug('listenIframe', 'PAYLOAD', PAYLOAD);
        /** nếu không phải từ iframe gửi tới thì thôi */
        if (from !== 'BBH-EMBED-IFRAME')
            return;
        /** Nếu có allowed_domains thì cập nhật lại */
        if (allowed_domains) {
            __classPrivateFieldSet(this, _EmbedChatManager_ALLOWED_DOMAINS, allowed_domains, "f");
            const IS_ALLOW = __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_checkAllowedDomain).call(this);
            if (!IS_ALLOW)
                return this.destroy();
        }
        /** Nếu có list hidden path thì lưu lại */
        if (list_hidden_path) {
            /** Lưu giá trị list hidden path  */
            __classPrivateFieldSet(this, _EmbedChatManager_HIDDEN_PATHS, list_hidden_path || [], "f");
            /** Ngay khi nhận được danh sách, kiểm tra lại pathname hiện tại */
            const CURRENT_PATHNAME = __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_getParentPathname).call(this);
            /**
             * Nếu có pathname hiện tại thì thực hiện xử lý
             */
            if (CURRENT_PATHNAME) {
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_handlePathnameChange).call(this, CURRENT_PATHNAME);
            }
        }
        /** thiết lập iframe khi mở popup */
        if (is_show) {
            /** Nếu có request PREVIEW_URL thì xoá margin và thiết lập trạng thái PREVIEW_URL */
            if (media_url) {
                /** Màn điện thoại thì margin = 0 (Ẩn btn và k để margin ) */
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, 0, 0, position);
                return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PREVIEW_URL');
            }
            this.debug('is show');
            /** setup thiết lập iframe khi mở popup,
             * Vì preview ảnh xoá mất margin
             */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, bottom !== null && bottom !== void 0 ? bottom : env_1.IFRAME_MARGIN_BOTTOM, position === 'bottom_left'
                ? left !== null && left !== void 0 ? left : env_1.IFRAME_MARGIN_RIGHT
                : right !== null && right !== void 0 ? right : env_1.IFRAME_MARGIN_RIGHT, position);
            /** nếu là máy tính thì hiển thị theo setup */
            if (!__classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this) && !media_url) {
                /**
                 * Nếu chiều cao hiện tại nhỏ hơn 674px thì thay đổi kích thước frame
                 */
                if (innerHeight < 674) {
                    return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PC_OPEN_HEIGHT');
                }
                else
                    return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PC_OPEN'); // sửa lại tên env
            }
            this.debug('is mobile');
            /** Màn điện thoại thì margin = 0 (Ẩn btn và k để margin ) */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, 0, 0, position);
            /** nếu là điện thoại thì hiển thị full màn hình // thay thiết lập thành env : MOBILE_IFRAME_WIDTH_OPEN */
            /**
             * Nếu có viewport thì hiển thị full dạng mobile
             * Nếu không có viewport thì hiển thị full dạng PC (để tránh lỗi vì không có viewport)
             */
            if (this.HAS_VIEWPORT) {
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'MOBILE_OPEN');
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, 0, 0, position);
            }
            else {
                /**
                 * Nếu không có viewport thì hiển thị full dạng PC (để tránh lỗi vì không có viewport)
                 */
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'PC_OPEN');
                /**
                 * Cài đặt margin cho iframe khi mở popup
                 */
                __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, bottom !== null && bottom !== void 0 ? bottom : 4, position === 'bottom_left' ? left !== null && left !== void 0 ? left : 12 : right !== null && right !== void 0 ? right : 12, position);
            }
            /** chặn scroll cha */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_disableScrollAndRefresh).call(this);
        }
        else {
            /** thiết lập iframe khi đóng popup */
            /** mở lại các thuộc tính đã chặn bên trên */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_enableScrollAndRefresh).call(this);
            /** thiết lập margin cách viền theo setup */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, bottom !== null && bottom !== void 0 ? bottom : env_1.IFRAME_MARGIN_BOTTOM, position === 'bottom_left'
                ? left !== null && left !== void 0 ? left : env_1.IFRAME_MARGIN_RIGHT
                : right !== null && right !== void 0 ? right : env_1.IFRAME_MARGIN_RIGHT, position);
            /** Trạng thái quick chat, điều chỉnh kích thước Iframe về trạng thái QUICK_CHATx */
            if (is_quick_chat)
                return __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'QUICK_CHAT', height);
            /** thu nhỏ về kích thước nút mở popup */
            __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'CLOSE');
        }
    });
}, _EmbedChatManager_scrollToHeader = function _EmbedChatManager_scrollToHeader() {
    window.scrollTo(0, 0);
}, _EmbedChatManager_disableScrollAndRefresh = function _EmbedChatManager_disableScrollAndRefresh() {
    /** Disable scroll and refresh */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_scrollToHeader).call(this);
    /** chặn scroll cha */
    document.body.style.overflow = 'hidden';
    /** Khi thay đổi kích thước thì kéo về đầu trang */
    window.addEventListener('resize', __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_scrollToHeader));
}, _EmbedChatManager_enableScrollAndRefresh = function _EmbedChatManager_enableScrollAndRefresh() {
    /** Enable scroll and refresh */
    document.body.style.overflow = 'auto';
    /** Gỡ event resize */
    window.removeEventListener('resize', __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_scrollToHeader));
}, _EmbedChatManager_disableParentScaling = function _EmbedChatManager_disableParentScaling() {
    this.debug('disableParentScaling', 'isMobile', __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this));
    /** chỉ cho chạy trên điện thoại */
    if (!__classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_isMobile).call(this))
        return;
    /**thẻ meta chặn zoom */
    const META_TAG = document.querySelector('meta[name="viewport"]');
    /**nội dung chặn zoom */
    const META_CONTENT = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
    /** nếu đã có thẻ thì thay nội dung */
    if (META_TAG)
        return (META_TAG.content = META_CONTENT);
    /** nếu chưa có thẻ thì tạo mới */
    /**thẻ meta mới tạo */
    const NEW_META_TAG = document.createElement('meta');
    /** tên thẻ */
    NEW_META_TAG.name = 'viewport';
    /** nội dung chặn zoom */
    NEW_META_TAG.content = META_CONTENT;
    /** thêm vào header */
    document.head.appendChild(NEW_META_TAG);
}, _EmbedChatManager_changeIframePosition = function _EmbedChatManager_changeIframePosition(iframe_margin_bottom, iframe_margin_x, position) {
    /** đặt vị trí xác định */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.position = 'fixed';
    /** cho iframe lên trên cùng index */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.zIndex = '999999';
    if (position === 'bottom_left') {
        /** căn trái */
        __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.left = `${iframe_margin_x}px`;
    }
    else {
        /** căn phải */
        __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.right = `${iframe_margin_x}px`;
    }
    /** căn dưới */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.bottom = `${iframe_margin_bottom}px`;
}, _EmbedChatManager_changeIframeSize = function _EmbedChatManager_changeIframeSize(type, height) {
    /** thay đổi kích thước theo từng điều kiện hiển thị */
    switch (type) {
        case 'PREVIEW_URL':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = '100vw';
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = '100vh';
            break;
        /** đóng popup */
        case 'CLOSE':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_CLOSE;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = env_1.IFRAME_HEIGHT_CLOSE;
            break;
        /** Khi mở popup Nhưng chiều cao màn hình nhỏ hơn 674px */
        case 'PC_OPEN_HEIGHT':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_OPEN;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = '100%';
            break;
        /** khi mở popup trên pc */
        case 'PC_OPEN':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_OPEN;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = env_1.IFRAME_HEIGHT_OPEN;
            break;
        /** Khi đóng popup nhưng có tin nhắn từ page đến */
        case 'QUICK_CHAT':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = env_1.IFRAME_WIDTH_QUICK_CHAT;
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height =
                height && height > 0 ? height + 'px' : env_1.IFRAME_HEIGHT_QUICK_CHAT;
            break;
        /** khi mở popup trên dt */
        case 'MOBILE_OPEN':
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.width = '100dvw';
            __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.height = '100dvh';
            break;
    }
}, _EmbedChatManager_initIframe = function _EmbedChatManager_initIframe() {
    /** loại bỏ khung */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('frameborder', '0');
    /** Thêm id cho iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('id', 'BBH-EMBED-IFRAME');
    /** Thêm Title cho iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('title', 'BBH-EMBED-IFRAME');
    /** Thêm loading cho iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('loading', 'lazy');
    this.debug('initIframe', 'page_id', __classPrivateFieldGet(this, _EmbedChatManager_PAGE_ID, "f"), window.innerWidth, window.innerHeight);
    this.debug('initIframe', 'locale', __classPrivateFieldGet(this, _EmbedChatManager_CONFIG, "f"));
    /** nạp đường dẫn của iframe + id trang + kích thước trang */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").setAttribute('src', `${env_1.IFRAME_SRC}?page_id=${__classPrivateFieldGet(this, _EmbedChatManager_PAGE_ID, "f")}&parentWidth=${window.innerWidth}&parentHeight=${window.innerHeight}&locale=${__classPrivateFieldGet(this, _EmbedChatManager_CONFIG, "f").locale}&has_viewport=${this.HAS_VIEWPORT}`);
    /** ẩn thanh cuộn */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").style.overflow = 'hidden';
    /** thiết lập vị trí của iframe */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframePosition).call(this, env_1.IFRAME_MARGIN_BOTTOM, env_1.IFRAME_MARGIN_RIGHT);
    /** thay đổi kích thước của iframe: hiện tại mặc định là không mở */
    __classPrivateFieldGet(this, _EmbedChatManager_instances, "m", _EmbedChatManager_changeIframeSize).call(this, 'CLOSE');
    /** tiêm iframe vào trang web */
    document.body.appendChild(__classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f"));
    this.debug('initIframe', 'SUCCESS');
    /** Gửi thông điệp init success */
    __classPrivateFieldGet(this, _EmbedChatManager_IFRAME, "f").onload = () => {
        this.debug('iframe loaded, sending init success message');
        /**
         * Delay 1s để đảm bảo iframe đã load xong
         */
        setTimeout(() => {
            this.debug('delay 1s');
            /**
             * Gửi thông điệp cho parent biết rằng iframe đã được khởi tạo thành công
             */
            window.parent.postMessage({
                from: 'RETION_EMBED',
                type: 'INIT_SUCCESS',
                message: 'Iframe has been initialized successfully!',
            }, '*');
        }, 1000);
    };
}, _EmbedChatManager_isValidParentDomain = function _EmbedChatManager_isValidParentDomain(currentDomain) {
    if (!currentDomain)
        return false;
    this.debug('isValidParentDomain', 'currentDomain', currentDomain);
    this.debug('isValidParentDomain', 'allowed_domains', __classPrivateFieldGet(this, _EmbedChatManager_ALLOWED_DOMAINS, "f"));
    if (__classPrivateFieldGet(this, _EmbedChatManager_ALLOWED_DOMAINS, "f").length === 0)
        return true;
    /** Danh sách domain hợp lệ (sẽ được cập nhật từ chat app) */
    return __classPrivateFieldGet(this, _EmbedChatManager_ALLOWED_DOMAINS, "f").includes(currentDomain);
};
