"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Base_IS_DEBUG;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Base = void 0;
/**màu xanh biển */
const BLUE = '\x1b[34m';
/**màu xanh lá */
const GREEN = '\x1b[32m';
/**xoá màu */
const RESET = '\x1b[0m';
/**lớp cơ sở */
class Base {
    constructor(is_debug) {
        /**chế độ debug */
        _Base_IS_DEBUG.set(this, void 0);
        // khởi tạo chế độ debug
        __classPrivateFieldSet(this, _Base_IS_DEBUG, is_debug || false, "f");
    }
    /**in ra log */
    debug(title, ...args) {
        // nếu không ở chế độ debug thì thôi
        if (!__classPrivateFieldGet(this, _Base_IS_DEBUG, "f"))
            return;
        // in ra log
        console.log(`${GREEN}[BBH]${RESET} ${BLUE}[${title}]${RESET}`, ...args);
    }
}
exports.Base = Base;
_Base_IS_DEBUG = new WeakMap();
