"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IFRAME_MARGIN_BOTTOM = exports.IFRAME_MARGIN_LEFT = exports.IFRAME_MARGIN_RIGHT = exports.IFRAME_MARGIN = exports.IFRAME_HEIGHT_QUICK_CHAT = exports.IFRAME_WIDTH_QUICK_CHAT = exports.IFRAME_HEIGHT_CLOSE = exports.IFRAME_WIDTH_CLOSE = exports.IFRAME_HEIGHT_OPEN = exports.IFRAME_WIDTH_OPEN = exports.IFRAME_SRC = void 0;
/**đường dẫn của iframe sẽ được hiển thị */
exports.IFRAME_SRC = process.env.IFRAME_SRC;
/**chiều rộng của iframe khi mở */
exports.IFRAME_WIDTH_OPEN = process.env.IFRAME_WIDTH_OPEN || '';
/**chiều cao của iframe khi mở */
exports.IFRAME_HEIGHT_OPEN = process.env.IFRAME_HEIGHT_OPEN || '';
/**chiều rộng của iframe khi đóng */
exports.IFRAME_WIDTH_CLOSE = process.env.IFRAME_WIDTH_CLOSE || '';
/**chiều cao của iframe khi đóng */
exports.IFRAME_HEIGHT_CLOSE = process.env.IFRAME_HEIGHT_CLOSE || '';
/**chiều rộng của iframe khi đóng. Nhưng có tin nhắn mới đến */
exports.IFRAME_WIDTH_QUICK_CHAT = process.env.IFRAME_WIDTH_QUICK_CHAT || '';
/**chiều cao của iframe khi đóng. Nhưng có tin nhắn mới đến */
exports.IFRAME_HEIGHT_QUICK_CHAT = process.env.IFRAME_HEIGHT_QUICK_CHAT || '';
/**khoảng cách giữa iframe và viền */
exports.IFRAME_MARGIN = Number(process.env.IFRAME_MARGIN);
/**khoảng cách giữa iframe và viền */
exports.IFRAME_MARGIN_RIGHT = Number(process.env.IFRAME_MARGIN_RIGHT);
/**khoảng cách giữa iframe và viền */
exports.IFRAME_MARGIN_LEFT = Number(process.env.IFRAME_MARGIN_LEFT);
/**khoảng cách giữa iframe và viền */
exports.IFRAME_MARGIN_BOTTOM = Number(process.env.IFRAME_MARGIN_BOTTOM);
